<template>
  <b-modal
    v-model="dataModal"
    centered
    no-close-on-backdrop
    no-close-on-esc
    title="Update Team"
    @close="onCloseModal">
    <div>
      <call-input
        v-model="$v.dataForm.name.$model"
        type-input="text"
        :valide="$v.dataForm.name.$error"
        float-name-label="Team Name" />
      <div
        :class="['call-select-modal__wrapper', 'call-input',
                 !dataForm.lead_id ? 'call-select--empty' : null,
                 $v.dataForm.lead_id.$error ? 'error-valide' : null]">
        <v-select
          v-model="$v.dataForm.lead_id.$model"
          :options="dataSelectOptionsTeamLeader"
          label="name"
          :components="{Deselect: dataComponentDeselect}"
          placeholder="Team Leader"
          class="call-select-modal call-select">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template slot="no-options">
            Введите имя Team Leader
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
        <label>Team Leader</label>
      </div>

      <div
        :class="['call-select-modal__wrapper', 'call-input',
                 !dataForm.sms_schema ? 'call-select--empty' : null,
                 $v.dataForm.sms_schema.$error ? 'error-valide' : null]">
        <v-select
          v-model="$v.dataForm.sms_schema.$model"
          :options="listSmsSchemas"
          label="name"
          :components="{Deselect: dataComponentDeselect}"
          placeholder="Call Mode"
          class="call-select-modal call-select">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
        <label>SMS Schema</label>
      </div>

      <div
        :class="['call-select-modal__wrapper', 'call-input',
                 !dataForm.call_mode ? 'call-select--empty' : null,
                 $v.dataForm.call_mode.$error ? 'error-valide' : null]">
        <v-select
          v-model="$v.dataForm.call_mode.$model"
          :options="dataSelectOptionsCallMode"
          label="name"
          :components="{Deselect: dataComponentDeselect}"
          placeholder="Call Mode"
          class="call-select-modal call-select">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
        <label>Call Mode</label>
      </div>

      <div
        v-if="computedIsManualMode"
        :class="['call-select-modal__wrapper', 'call-input',
                 !dataForm.manual_operator_ids ? 'call-select--empty' : null,
                 $v.dataForm.manual_operator_ids.$error ? 'error-valide' : null]">
        <v-select
          v-model="$v.dataForm.manual_operator_ids.$model"
          :options="dataSelectOptionsOperatorsWithManualMode"
          multiple
          label="name"
          :components="{Deselect: dataComponentDeselect}"
          placeholder="Operators With Manual Mode"
          class="call-select-modal call-select">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
        <label>Operators With Manual Mode</label>
      </div>
      <call-input
        v-model="$v.dataForm.timezone_offset.$model"
        type-input="number"
        :valide="$v.dataForm.timezone_offset.$error"
        float-name-label="Timezone Offset" />
    </div>
    <template #modal-footer>
      <call-button
        :icon="dataCloseIcon"
        type="danger"
        plain
        @click="onCloseModal()">
        Cancel
      </call-button>
      <call-button
        :icon="dataRefreshIcon"
        :disabled="$v.$invalid"
        type="success"
        @click="clickUpdateTeam()">
        Update team
      </call-button>
    </template>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import CallButton from '../CallButton';
import CallInput from '../CallInput';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { ID_ROLE_OPERATOR, ID_ROLE_TEAM_LEADER } from '../../../service/consts';
import { TEAMS_GET, TEAMS_UPDATE, USERS_GET_ALL } from '../../../store/types/actions.types';
import iconsModal from '../../../mixins/iconsModal';
import selectTeamLeader from '../../../mixins/select/selectTeamLeader';
import { mapGetters } from 'vuex';

export default {
  name: 'CallModalUpdateTeam',
  components: {
    CallInput,
    CallButton,
    BModal
  },
  mixins: [validationMixin, iconsModal, selectTeamLeader],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    teamId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      dataModal: this.value,
      dataSelectOptionsOperatorsWithManualMode: [],
      dataSelectOptionsCallMode: [
        {
          id: 'asterisk',
          name: 'Asterisk'
        },
        {
          id: 'custom',
          name: 'Custom'
        },
        {
          id: 'manual',
          name: 'Manual'
        }
      ],
      dataForm: {
        name: null,
        lead_id: null,
        call_mode: null,
        sms_schema: null,
        manual_operator_ids: null,
        timezone_offset: 0
      }
    };
  },
  validations: {
    dataForm: {
      name: {
        required
      },
      lead_id: {
        required
      },
      call_mode: {
        required
      },
      sms_schema: {
        required
      },
      manual_operator_ids: {
        required: requiredIf(function () {
          return this.dataForm.call_mode?.id === 'custom';
        })
      },
      timezone_offset: {
        required
      }

    }
  },
  computed: {
    ...mapGetters({
      smsSchemas: 'misc/smsSchemas'
    }),

    listSmsSchemas () {
      const ucFirst = (str) => {
        if (!str) return str;
        return str[0].toUpperCase() + str.slice(1);
      };

      return this.smsSchemas.map((item) => ({
        id: item,
        name: ucFirst(item)
      }));
    },

    computedIsManualMode () {
      return this.dataForm.call_mode?.id === 'custom';
    }
  },
  watch: {
    async value () {
      this.dataModal = this.value;
      if (this.dataModal) {
        Object.assign(this.$data, this.$options.data.apply(this));
        this.$v.$reset();

        const [{ payload: { list: listTeamLeader = [] } }, { team }, { payload: { list: listOperators = [] } }] = await Promise.all([
          this.$store.dispatch(`user/${USERS_GET_ALL}`, {
            params: {
              role: ID_ROLE_TEAM_LEADER,
              short: true,
              without_blocked: true
            }
          }),
          this.$store.dispatch(`team/${TEAMS_GET}`, {
            params: {
              id: this.teamId
            }
          }),
          this.$store.dispatch(`user/${USERS_GET_ALL}`, {
            params: {
              role: ID_ROLE_OPERATOR,
              team_ids: [this.teamId],
              short: true,
              without_blocked: true
            }
          })
        ]);

        this.dataSelectOptionsTeamLeader = listTeamLeader.filter(item => !item.is_blocked);
        this.dataSelectOptionsOperatorsWithManualMode = listOperators.filter(item => !item.is_blocked);
        this.$set(this.dataForm, 'name', team?.name);
        this.$set(this.dataForm, 'call_mode', this.dataSelectOptionsCallMode.find(item => item.id === team?.call_mode));
        this.$set(this.dataForm, 'lead_id', { id: team?.lead_id, name: team?.lead_name });
        this.$set(this.dataForm, 'manual_operator_ids', listOperators.filter(item => item.call_mode === 'manual'));
        this.$set(this.dataForm, 'sms_schema', this.listSmsSchemas.find(item => item.id === team?.sms_schema));
        this.$set(this.dataForm, 'timezone_offset', team?.timezone_offset);
      }
    }
  },
  methods: {
    onCloseModal () {
      this.dataModal = false;
      this.$emit('input', false);
    },
    async clickUpdateTeam () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      try {
        await this.$store.dispatch(`team/${TEAMS_UPDATE}`, {
          teamId: this.teamId,
          params: {
            name: this.dataForm?.name,
            call_mode: this.dataForm?.call_mode?.id,
            sms_schema: this.dataForm?.sms_schema?.id,
            manual_operator_ids: this.computedIsManualMode ? this.dataForm?.manual_operator_ids.map(item => item.id) : null,
            lead_id: this.dataForm?.lead_id?.id,
            timezone_offset: Number(this.dataForm?.timezone_offset)
          },
          context: this
        });
        this.dataForm.name = null;
        this.dataForm.lead_id = null;
        this.$emit('on-update');
        this.$Notify({
          title: 'Team',
          message: 'Successfully updated',
          type: 'success'
        });
        this.onCloseModal();
      } catch (e) {
        throw Error(e);
      }
    }
  }
};
</script>
