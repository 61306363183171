import { faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  data () {
    return {
      dataSelectTeamLeaderValue: null,
      dataSelectOptionsTeamLeader: [],
      dataIconDown: faChevronDown,
      dataComponentDeselect: {
        render: createElement => createElement(FontAwesomeIcon, {
          props: {
            icon: faTimes
          }
        })
      }
    };
  }
};
