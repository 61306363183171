<template>
  <div class="table_actions">
    <call-tooltip
      placement="top"
      content="Info">
      <call-button
        outlet
        type="success"
        round
        not-border
        :icon="dataViewIcon"
        @click="clickAction('view')" />
    </call-tooltip>
    <call-tooltip
      v-if="computedIsAdmin"
      placement="top"
      content="Edit">
      <call-button
        outlet
        type="warning"
        round
        not-border
        :icon="dataReadingIcon"
        @click="clickAction('update')" />
    </call-tooltip>
    <call-tooltip
      v-if="computedIsAdmin"
      placement="top"
      :content="!block ? 'Deactivate' : 'Activate'">
      <call-button
        outlet
        :type="!block ? 'danger' : 'success'"
        round
        not-border
        :icon="dataLockIcon"
        @click="clickAction('blocked')" />
    </call-tooltip>
  </div>
</template>

<script>
import CallButton from '../common/CallButton';
import { faEye, faPen, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { mapGetters } from 'vuex';

export default {
  name: 'CallTableTeamsActions',
  components: { CallButton },
  props: {
    block: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataViewIcon: faEye,
      dataReadingIcon: faPen,
      dataLockIcon: faPowerOff
    };
  },
  computed: {
    ...mapGetters({
      computedIsAdmin: 'auth/isAdmin',
      computedIsModerator: 'auth/isModerator',
      computedCurrentUser: 'auth/currentUser'
    })
  },
  methods: {
    clickAction (action) {
      this.$emit('on-action', action);
    }
  }
};
</script>
