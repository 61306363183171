<template>
  <b-modal
    v-model="dataModal"
    centered
    no-close-on-backdrop
    no-close-on-esc
    title="Add Team"
    @close="onCloseModal">
    <div>
      <call-input
        v-model="$v.dataForm.name.$model"
        type-input="text"
        :valide="$v.dataForm.name.$error"
        float-name-label="Team Name" />
      <div
        :class="['call-select-modal__wrapper', 'call-input',
                 !dataForm.leadId ? 'call-select--empty' : null,
                 $v.dataForm.leadId.$error ? 'error-valide' : null]">
        <v-select
          v-model="$v.dataForm.leadId.$model"
          :options="dataSelectOptionsTeamLeader"
          label="name"
          placeholder="Team Leader"
          :components="{Deselect: dataComponentDeselect}"
          class="call-select-modal call-select">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template slot="no-options">
            Введите имя Team Leader
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
        <label>Team Leader</label>
      </div>
      <call-input
        v-model="$v.dataForm.timeZoneOffset.$model"
        type-input="number"
        :valide="$v.dataForm.timeZoneOffset.$error"
        float-name-label="Timezone Offset" />
    </div>
    <template #modal-footer>
      <call-button
        :icon="dataCloseIcon"
        type="danger"
        plain
        @click="onCloseModal()">
        Cancel
      </call-button>
      <call-button
        :icon="dataAddIcon"
        :disabled="$v.$invalid"
        type="success"
        @click="clickAdd()">
        Add Team
      </call-button>
    </template>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import CallButton from '../CallButton';
import CallInput from '../CallInput';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { TEAMS_CREATE, USERS_GET_ALL } from '../../../store/types/actions.types';
import iconsModal from '../../../mixins/iconsModal';
import { ID_ROLE_TEAM_LEADER } from '../../../service/consts';
import selectTeamLeader from '../../../mixins/select/selectTeamLeader';

export default {
  name: 'CallModalAddTeam',
  components: {
    CallInput,
    CallButton,
    BModal
  },
  mixins: [validationMixin, iconsModal, selectTeamLeader],
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataModal: this.value,
      dataForm: {
        name: null,
        leadId: null,
        timeZoneOffset: null
      }
    };
  },
  validations: {
    dataForm: {
      name: {
        required
      },
      timeZoneOffset: {
        required
      },
      leadId: {
        required
      }
    }
  },
  watch: {
    async value () {
      this.dataModal = this.value;
      if (this.dataModal) {
        Object.assign(this.$data, this.$options.data.apply(this));
        this.$v.$reset();

        const { payload: { list: listTeamLeader = [] } } = await this.$store.dispatch(`user/${USERS_GET_ALL}`, {
          params: {
            role: ID_ROLE_TEAM_LEADER,
            short: true,
            without_blocked: true
          }
        });
        this.dataSelectOptionsTeamLeader = listTeamLeader.filter(item => !item.is_blocked);
      }
    }
  },
  methods: {
    onCloseModal () {
      this.$emit('input', false);
    },
    async clickAdd () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      try {
        await this.$store.dispatch(`team/${TEAMS_CREATE}`, {
          params: {
            name: this.dataForm.name,
            lead_id: this.dataForm.leadId?.id,
            timezone_offset: Number(this.dataForm.timeZoneOffset)
          },
          context: this
        });
        this.onCloseModal();
        this.$emit('on-update');
      } catch (e) {
        throw Error(e);
      }
    }
  }
};
</script>
